@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior: none; /* Prevents bounce effect */
  overflow-x: hidden; /* Ensures no horizontal scroll */
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

::-webkit-scrollbar {
  display: none;
}
